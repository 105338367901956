import { FC } from 'react'
import PageContentWrapper from 'shared/components/PageContentWrapper'
import { StorageReference } from 'firebase/storage'
import ContentContainer from 'shared/components/ContentContainer'
import { Button, Flex } from '@chakra-ui/react'
import PageContent from 'shared/components/PageContent'
import has from 'lodash/has'
import {
  ICourse,
  IFeedback,
  IItemInfo,
  IPartition,
  ITeacher,
  IUser,
  SectionType
} from 'shared/types'
import PageContentHeader from 'shared/components/PageContentHeader'
import AddSectionButton from 'shared/components/admin/AddSectionButton'
import dayjs from 'dayjs'
import ContactForm from 'shared/components/ContactForm'

type Props = {
  course: ICourse
  onPay: () => void
  toCourse: () => void
  userCourses: Record<string, ICourse>
  storageRef: (refer: string) => StorageReference
  admin?: {
    onAddSection: (sType: SectionType, i: number) => void
    onMoveSection: (fromIndex: number, toIndex: number) => void
    onEditSection: (sid: string) => void
    onEditHeader?: () => void
  }
  user?: IUser | null
  dbSaveFeedback?: (feedback: Partial<IFeedback>) => void
  teachers: Record<string, ITeacher>
  partitions: Record<string, IPartition> | null
}

const CoursePage: FC<Props> = ({
  course,
  storageRef,
  toCourse,
  onPay,
  userCourses,
  admin,
  user = null,
  dbSaveFeedback,
  teachers,
  partitions
}) => {
  const renderButton = () => {
    if (course) {
      if (has(userCourses, course.id)) {
        return (
          <Button
            variant='primary'
            w={{ base: 'full', lg: 'auto' }}
            onClick={toCourse}
          >
            Начать обучение
          </Button>
        )
      } else {
        return (
          <Button
            variant='primary'
            w={{ base: 'full', lg: 'auto' }}
            isDisabled={course.closed}
            onClick={onPay}
          >
            {course.closed ? 'Запись закрыта' : 'Купить'}
          </Button>
        )
      }
    }
  }

  const renderHeader = () => {
    if (course) {
      const sDate = dayjs(course.startDate)
      const eDate = dayjs(course.endDate)
      return (
        <PageContentHeader
          partition={`${sDate.format('D MMMM')} - ${eDate.format('D MMMM')}`}
          level={`${eDate.diff(sDate, 'weeks')} недель`}
          title={course.title}
          price={course.price}
          oldPrice={course.oldPrice}
          priceEur={course.priceEur}
          oldPriceEur={course.oldPriceEur}
          videoId={course.vimeoVideoUrl}
          tags={course.tags || []}
          onEditHeader={admin?.onEditHeader}
        >
          {renderButton()}
          {admin && (
            <AddSectionButton
              lt={undefined}
              addSection={(sType: SectionType) => admin.onAddSection(sType, 0)}
            />
          )}
        </PageContentHeader>
      )
    }
  }

  return (
    <PageContentWrapper>
      {renderHeader()}
      <ContentContainer
        bg='white'
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        py={{ base: 6, lg: 8 }}
        minH='80vh'
        backBg={dbSaveFeedback && 'white'}
      >
        <PageContent
          sections={course.sections}
          sectionsOrder={course.sectionsOrder}
          entityId={course.id}
          storageRef={storageRef}
          admin={admin}
          teachers={teachers}
          partitions={partitions}
          wordCards={{}}
          cardTags={null}
        />
      </ContentContainer>
      {dbSaveFeedback && (
        <ContentContainer
          bg='white'
          borderTopRadius={{ base: 'sm', lg: 'lg' }}
          borderBottomRadius={{ base: 'sm', lg: 'lg' }}
          py={{ base: 6, lg: 8 }}
          backBg={'white'}
        >
          <Flex w='full' justify='center'>
            <ContactForm user={user} dbSaveFeedback={dbSaveFeedback} />
          </Flex>
        </ContentContainer>
      )}
    </PageContentWrapper>
  )
}

export default CoursePage
