import {
  onSnapshot,
  query,
  collection,
  doc,
  updateDoc,
  setDoc,
  writeBatch
} from 'firebase/firestore'
import { db, dbOmit, generateId } from 'src/controllers/db'
import { ICourse, IDay, IEntityType, ILesson, IWeek } from 'shared/types'
import { receiveLibrary } from 'src/model/actions'
import store from 'src/model/store'
import { addListener } from 'controllers/listeners'
import dayjs from 'dayjs'
import forEach from 'lodash/forEach'

export const dbFetchLibrary = async () => {
  try {
    const q = query(collection(db, 'library'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: Record<string, ICourse> = {}
        sn.forEach(doc => {
          const p = doc.data() as ICourse
          res[doc.id] = {
            ...p,
            id: doc.id,
            createdAt: +dayjs(p.createdAt || 0)
          }
        })
        store.dispatch(receiveLibrary(res))
      },
      err => {
        console.log(`dbFetchLibrary error: ${err.message}`)
      }
    )
    addListener('library', unsubscribe)
  } catch (e) {
    console.error('dbFetchLibrary error', e)
  }
}

export const dbUpdateLibraryCourse = async (
  courseId: string,
  upd: Partial<ICourse>
) => {
  try {
    console.log('dbUpdateLibraryCourse', courseId, upd)
    const ref = doc(collection(db, 'library'), courseId)
    await updateDoc(ref, dbOmit(upd))
  } catch (e) {
    console.log('dbUpdateLibraryCourse error', e)
  }
}

export const dbCreateLibraryCourse = async () => {
  const id = generateId()
  const c: ICourse = {
    id,
    title: 'Новый курс',
    invisible: true,
    closed: true,
    price: 0,
    lessons: [],
    sections: {},
    sectionsOrder: [],
    createdAt: +dayjs()
  }
  const ref = doc(collection(db, 'library'), id)
  await setDoc(ref, dbOmit(c))
  return id
}

export const dbDeleteLibraryCourse = async (c: ICourse) => {
  const batch = writeBatch(db)
  batch.delete(doc(collection(db, 'library'), c.id))
  if (c.lessons) {
    for (const lId of c.lessons) {
      batch.delete(doc(collection(db, 'lessons'), lId))
    }
  }
  await batch.commit()
}

export const dbDuplicateLibraryCourse = async (
  c: ICourse,
  lessons: Record<string, ILesson>
) => {
  const batch = writeBatch(db)
  const courseId = generateId()
  const newCourse: ICourse = {
    ...c,
    id: courseId,
    lessons: [],
    closed: true,
    invisible: true,
    createdAt: +dayjs(),
    title: c.title + ' (copy)',
    copyOf: {
      entityId: c.id,
      entityType: IEntityType.LIBRARY
    }
  }
  if (c.lessons) {
    for (const lId of c.lessons) {
      const l = lessons[lId]
      if (l) {
        const newL: ILesson = {
          ...l,
          id: generateId(),
          entityId: courseId
        }
        newCourse.lessons.push(newL.id)
        batch.set(doc(collection(db, 'lessons'), newL.id), newL)
      }
    }
  }
  if (c.content) {
    const newContent: IWeek[] = []
    forEach(c.content, w => {
      const newWeek: IWeek = { ...w, days: [], enabled: false }
      forEach(w.days, d => {
        const newDay: IDay = { ...d, lessons: [] }
        forEach(d.lessons, lId => {
          const l = lessons[lId]
          if (l) {
            const newL: ILesson = {
              ...l,
              id: generateId(),
              entityId: courseId
            }
            newDay.lessons.push(newL.id)
            // console.log('add new lesson to batch', newL)
            batch.set(doc(collection(db, 'lessons'), newL.id), newL)
          }
        })
        newWeek.days.push(newDay)
      })
      newContent.push(newWeek)
    })
    newCourse.content = newContent
  }

  // console.log('new course', newCourse)

  batch.set(doc(collection(db, 'library'), courseId), newCourse)
  await batch.commit()
  return courseId
}
